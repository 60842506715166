.word--meta--container {
  display: flex;
  flex-direction: column;

  align-items: center;

  margin: 0.5em 0 1.15em 0;
}

.word--meta--hsk--level {
  font-size: 1.5em;
  letter-spacing: -0.05em;
  font-weight: 500;

  text-align: center;
}

.word--meta--hidden--container {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;

  width: 10vw;
}

.word--meta--sound {
  margin-top: 5px;
  height: 15px;
  width: 15px;
}

.word--meta--sound:hover {
  cursor: pointer;
}

.word--meta--copy {
  width: auto;
  height: 15px;
}

.word--meta--copy:hover {
  cursor: pointer;
}
