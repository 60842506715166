.word--display {
  height: 15vh;
  min-width: 40vh;

  background-color: white;

  font-size: 6.5em;

  display: flex;
  justify-content: center;
  align-items: center;

  /* position: relative;
  bottom: 0.15em; */

  margin: 0 0 0.1em 0;
}
