.wordlevel-card {
  width: 60vh;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1em;
  transition: box-shadow 0.3s;
}

.wordlevel-card__word-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.wordlevel-card--weak {
  background-color: #f4cccc; /* Softer red */
}

.wordlevel-card--medium {
  background-color: #fff2cc; /* Softer yellow */
}

.wordlevel-card--strong {
  background-color: #d9ead3; /* Softer green */
}

.wordlevel-card__title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  color: #333;
  text-decoration: none;
}

.wordlevel-card__word {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 25px;
  border: 1px solid black;

  font-size: 1.5em;

  margin: 0.25em;

  min-width: 100px;
  max-width: 150px;
  min-height: 50px;
  max-height: 100px;

  cursor: pointer;
}
