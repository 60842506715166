.garden--plantsList--head {
  margin: 3vh 0;
}

.garden--plantsList--meta {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: nowrap;

  font-size: 1.2em;
}

.garden--seedcount--section {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  min-width: 8rem;
}

.garden--streak--section {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  min-height: 2em;
  max-height: 2em;

  padding-left: 0.25em;

  margin-right: auto;
}

.pulsate-fwd {
  -webkit-animation: pulsate-fwd 0.75s ease-in-out infinite both;
  animation: pulsate-fwd 0.75s ease-in-out infinite both;
}

@keyframes pulsate-fwd {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
