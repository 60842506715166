.login--container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  font-size: 1.5em;
}

.login--container > form {
  height: 20vh;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.login--container > form > input {
  height: 2em;
  width: 15vw;
}

.login--button {
  background-color: rgb(23, 118, 77);
  color: white;

  font-size: 1em;
}
