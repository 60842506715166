html,
body {
  height: 100%;
  background-color: #e2d8c0;
}

nav {
  height: 2vh;
  max-width: 100vw;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding: 1em 0.5em;

  flex-wrap: wrap;
}

.nav--items {
  display: flex;
  justify-content: flex-end;

  width: 100%;

  margin-top: 2vh;
}

.nav--title {
  color: #26c065;
  font-weight: 600;
  font-size: 1.25rem;
  letter-spacing: -0.03em;

  /* margin-bottom: 1em; */
  margin-right: auto;

  /* white-space: nowrap; */
}

.nav--select {
  background-color: transparent;

  border: none;
  padding-right: 0.75em;
  font-size: 0.9em;
  cursor: inherit;

  background-color: transparent;

  color: #26c065;
}

.nav--difficulty {
  font-size: 0.9em;
  color: #26c065;
  font-weight: 600;

  margin-right: 0.5em;
  margin-top: 0.1em;
}

.nav--login > a {
  text-decoration: none;
  color: #26c065;
  font-weight: 600;
}

/*----------  Responsive Nav  ----------*/
.nav--left--side {
  flex: 30%;
}

.nav--links--container {
  display: flex;
  justify-content: flex-end;
}

.nav--links--container > a {
  text-decoration: none;
}

.nav--links--container > button {
  text-decoration: none;
  border: none;
  background-color: transparent;
  font-family: inherit;
  padding: 0;
  cursor: pointer;

  @media screen and (-ms-high-contrast: active) {
    border: 2px solid currentcolor;
  }

  font-size: inherit;
  font-weight: 400;
}

.nav--links--container > * {
  margin: 0 1em;
}

.nav--right--side {
  flex: 50%;
}

/* Button */

.burger--button {
  display: none;
  margin: 0 1em 0 auto;

  background-color: transparent;
  border: 0;

  color: #26c065;
}

@media only screen and (max-width: 1250px) {
  .burger--button {
    display: flex;
  }

  .nav--links--container {
    display: none;
  }

  #hidden {
    position: absolute;
    right: 0px;
    top: 50px;

    height: 40vh;
    width: 100vw;

    background-color: #efebe1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    padding-bottom: 1em;

    z-index: 9999;
  }
}
