.button--container {
  width: 80%;
  height: 7.5vh;

  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-top: 1.25em;
  margin-bottom: 1.5em;
}
