.adminWordSortComponent--header {
  display: flex;
  align-items: center;
}

.adminWordSortComponent--downArrow {
  height: 2em;
  width: 2em;

  color: #144c23;
}
