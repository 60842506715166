nav {
  height: 2vh;
  max-width: 100vw;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding: 2em;

  flex-wrap: wrap;

  margin-bottom: 2em;
}

.nav--items {
  display: flex;
  justify-content: flex-end;

  width: 100%;

  margin-top: 2vh;
}

.nav--title {
  color: #26c065;
  font-weight: 600;
  font-size: clamp(1em, 3vw, 1.5em);
  letter-spacing: -0.03em;

  margin-right: auto;
}

.nav--select {
  background-color: transparent;

  border: none;
  padding-right: 0.75em;
  font-size: 0.9em;
  cursor: inherit;

  background-color: transparent;

  color: #26c065;
}

.nav--difficulty {
  font-size: 0.9em;
  color: #26c065;
  font-weight: 600;

  margin-right: 0.5em;
  margin-top: 0.1em;
}

.nav--login > a {
  text-decoration: none;
  color: #26c065;
  font-weight: 600;
}

.nav--login {
}

/* iphone 6se */
@media only screen and (max-height: 468px) {
  nav {
    height: 2vh;
    /* width: 100vw; */

    background-color: #fbf9f4;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2em 0.5em;

    margin-bottom: 4em;
  }
}
