.word--translated {
  height: 1.2em;
  font-size: 0.9em;

  position: relative;
  top: 2px;

  /* background-color: red; */
  width: 13rem;

  text-align: center;

  margin-bottom: 1em;
}
