.button {
  height: 100%;

  font-size: 2em;

  margin-top: 0.5em;
}

.button--false--image {
  height: 60%;
}

.button--true--image {
  height: 80%;
}

.button--false {
  border: 0px;
  background-color: white;
}

.button--false:hover {
  cursor: pointer;
}

.button--true {
  border: 0px;
  background-color: white;
}

.button--true:hover {
  cursor: pointer;
}
