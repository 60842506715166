.word--display--container {
  background-color: white;

  position: relative;
  top: clamp(40px, 10%, 50px);

  min-height: 22em;
  min-width: 22em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25%;
}
