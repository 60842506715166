main {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 45vh;

  /* position: absolute; */
}

.body--selectLevel {
  color: #26c065;
  font-weight: 600;
}
/* iphone 6se */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (width: 375px) and (height: 559px) and (orientation: portrait) and (color: 8) and (device-aspect-ratio: 375/667) and (aspect-ratio: 375/559) and (device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 2) {
  main {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 45vh;

    background-color: #fbf9f4;

    margin-bottom: 5em;
  }
}

.body--select {
  background-color: transparent;

  border: none;
  padding-right: 0.75em;
  font-size: 1.5em;
  cursor: inherit;

  background-color: transparent;

  color: #26c065;
}
