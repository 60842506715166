.garden {
  min-height: 50vh;

  background-color: #0c312d;

  color: white;

  border-radius: 20px 20px 0px 0px;

  padding: 1em;

  margin-top: 4vh;
}
/* iphone 6se */
@media only screen and (max-height: 468px) {
  .garden {
    min-height: 50vh;
    /* max-height: 30vh; */

    background-color: #0c312d;

    color: white;

    border-radius: 20px 20px 0px 0px;

    padding: 1em;

    margin-top: 2.5em;
  }
}

.garden--head--buttons--container {
  display: flex;
  justify-content: space-between;

  width: 20vw;
}

.garden--download--text:hover {
  cursor: pointer;
}

/* plants */

.known--words--container {
  min-height: 20vh;
}

.plant--container {
  display: flex;
  flex-wrap: wrap;

  width: 95%;

  margin: 0 auto 0 auto;
}

.plant--pair {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #26c065;

  border-radius: 25px;

  margin: 0.25em;

  min-width: 125px;
  max-width: 200px;
  min-height: 125px;
  max-height: 200px;
}

.plant--pair--word {
  font-size: 1.25em;
  color: black;

  margin: 0;
}

.plant--pair--image {
  height: 2.5em;
}

/* seeds */

.garden--seedsList--head {
  font-size: 1.2em;
  display: flex;
  justify-content: space-between;

  margin: 1em 0;
}

.seed--container {
  display: flex;
  flex-wrap: wrap;

  width: 95%;

  margin: 0 auto 0 auto;
}

.seed--pair {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #45382c;

  border-radius: 25px;

  min-width: 125px;
  max-width: 200px;
  min-height: 125px;
  max-height: 200px;

  margin: 0.25em;
  padding: 0em;
}

.seed--pair--word {
  font-size: 1.25em;
  color: rgb(255, 255, 255);

  margin: 0;
  padding: 0em;
}

.seed--pair--image {
  height: 2.5em;
  margin-bottom: 0.2em;
}

.seed--pair--detail {
  margin: 0.5em 0 0 0;

  font-size: 0.8em;

  text-align: center;
}
